import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  root: {
    background: "#363762",
    display: "flex",
    alignItems: "center",
    boxShadow: "none"
  },
  button: {
    background: "#8100FF",
    color: "#fff",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#5200a5"
    }
  },
  heading: {
    color: "hsla(0,0%,100%,.8)"
  }
});

export const UnstyledPerformanceDetailsSubMenu = props => {
  const { classes, onClickBack } = props;
  return (
    <AppBar position="static" className={classes.root}>
      <Container disableGutters maxWidth="lg">
        <Toolbar>
          <Button
            onClick={onClickBack}
            variant="contained"
            className={classes.button}
          >
            Back
          </Button>
          <Typography variant="h6" className={classes.heading}>
            Live Performance
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const PresentationalPerformanceDetailsSubMenu = withStyles(styles)(
  UnstyledPerformanceDetailsSubMenu
);

const PerformanceDetailsSubmenu = withRouter(props => {
  const { history } = props;
  return (
    <PresentationalPerformanceDetailsSubMenu onClickBack={history.goBack} />
  );
});

export default PerformanceDetailsSubmenu;
