import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SearchBox from "components/forms/SearchBox";
import { currentAuthenticatedUser } from "redux/actions/loginActions";
import { PERFORMANCE_STATUSES, CMOR, PAR } from "concertify/constants";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    background: "#373762",
    padding: theme.spacing.unit,
    [theme.breakpoints.down("md")]: {
      padding: 10
    }
  },
  filters: {
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2)
    }
  },
  button: {
    color: "hsla(0, 0%, 100%, .8)",
    maxHeight: 35
  },
  current: {
    color: "#fff",
    background: "#8100ff"
  },
  addPerformanceButton: {
    boxShadow: "none",
    background: "#008DC7",
    color: "#fff"
  }
});

class UnconnectedPerformancesSubMenu extends React.Component {
  constructor(props) {
    super(props);
    this.filters = ["All", ...PERFORMANCE_STATUSES];
    this.state = {
      filter: this.props.filter
    };
  }

  render() {
    const { classes, variant } = this.props;
    return (
      <Grid
        container
        className={classes.root}
        alignItems="center"
        justify="center"
      >
        <Container disableGutters maxWidth="lg">
          <Grid item container alignItems="center" justify="space-between">
            <div className={classes.filters}>
              {this.filters.map(filter => (
                <FilterButton
                  classes={this.props.classes}
                  active={filter === this.props.filter}
                  key={filter + this.props.filter}
                  onClick={this.props.setFilter}
                >
                  {filter}
                </FilterButton>
              ))}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {variant === CMOR && (
                <Button
                  color="secondary"
                  component={Link}
                  to="/performances/new"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  data-role="addPerformanceButton"
                >
                  + Add Performance
                </Button>
              )}
              <SearchBox
                placeholder="Filter by artist name"
                onChange={this.props.setKeywordFilter}
              />
            </div>
          </Grid>
        </Container>
      </Grid>
    );
  }
}

const FilterButton = ({ classes, active, children, ...rest }) => (
  <Button
    className={
      active ? [classes.button, classes.current].join(" ") : classes.button
    }
    {...rest}
  >
    {children}
  </Button>
);

UnconnectedPerformancesSubMenu.defaultProps = {
  filter: "All",
  variant: CMOR
};

UnconnectedPerformancesSubMenu.propTypes = {
  currentAuthenticatedUser: PropTypes.func.isRequired,
  setKeywordFilter: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([CMOR, PAR])
};

const mapStateToProps = state => ({
  user: state.loginReducer.user
});

const CreatePerformanceView = connect(mapStateToProps, {
  currentAuthenticatedUser
})(UnconnectedPerformancesSubMenu);

export default withStyles(styles)(CreatePerformanceView);
