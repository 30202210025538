import { makeStyles } from "@material-ui/core";

const styles = makeStyles(theme => {
  return {
    table: {
      backgroundColor: "white"
    },
    headerCell: {
      "&.MuiTableCell-root": {
        fontSize: "16px",
        fontWeight: 400,
        color: "#5A5A5A",
        width: "12.5%"
      }
    },
    rowCell: {
      "&.MuiTableCell-root": {
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        width: "12.5%"
      }
    },
    headerRow: {
      height: "64px"
    },
    contentRow: {
      height: "98px",
      textDecoration: "none",
      cursor: "pointer"
    }
  };
});

export { styles };
