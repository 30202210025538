import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 750
  }
});

const PrivacyStatementView = ({ classes }) => (
  <Grid container direction="column" alignItems="center" justify="center">
    <Grid data-role="privacy-statement" item className={classes.root}>
      <Typography variant="h5" gutterBottom>
        PRIVACY STATEMENT
      </Typography>
      <Typography variant="body1" align="justify">
        Mind Your Rights Oy (“<strong>MYR</strong>” or “<strong>we</strong>”)
        processes personal data of the users of the music rights management
        Software as a Service provided by MYR ("<strong>Concertify</strong>
        ") and the visitors of the website{" "}
        <a
          href="http://www.mindyourrights.fi/"
          target="_blank"
          rel="noopener noreferrer"
        >
          mindyourrights.fi
        </a>
        (“<strong>Website</strong>”).
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        In this Privacy Statement, the word "<strong>Services</strong>” refers
        jointly to Concertify and the Website. The word “
        <strong>User Organization</strong>” refers to the collective management
        organizations and other organizations registered to and using
        Concertify. The word “<strong>User</strong>” or “<strong>you</strong>”
        refers jointly to the representatives or users of the User Organizations
        as well as the unregistered visitors of the Website.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Our Privacy Statement explains,for example,the types of personal data we
        process, how we process the personal data and how you may use your
        rights as a data subject.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Some of our services might be subject to a separate privacy policy. If a
        separate privacy policy applies to a particular service, we will post it
        in connection with the service in question.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        This Privacy Statement may be updated from time to time in order to
        reflect the changes in data processing practices or otherwise. You can
        find the current version on the Website. We will not make substantial
        changes to this Privacy Statement or reduce the rights of the Users
        under this Privacy Statement without providing a notice thereof to the
        Users who have provided us with their email address.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        This Privacy Statement applies to processing of personal data carried
        out by MYR as a data controller. For clarity we emphasize that this
        Privacy Statement does not address and is not applicable to the
        processing of personal data collected by the User Organizations or other
        third parties. We encourage you to familiarize yourself with the privacy
        policies of such third parties.
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        1. MYR's contact details
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Name: Mind Your Rights Oy
        <br />
        Company ID: 2863486-5
        <br />
        Correspondence address: Urho Kekkosen katu2 C, 00100 Helsinki
        <br />
        Phone number: (09) 681 011
        <br />
        E-mail address: info@mindyourrights.fi
        <br />
        <a href="https://www.mindyyourrights.fi">www.mindyourrights.fi</a>
        <br />
        <br />
        Contact person: Roope Pajasmaa,{" "}
        <a href="mailto:roope.pajasmaa@mindyourrights.fi">
          roope.pajasmaa@mindyourrights.fi
        </a>
        , +358 50 3658351
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        2. Personal data processed and sources of data
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The personal data collected and processed by us in connection with your
        registration and use of the Services can be divided into two general
        data categories: User Data and Analytics Data.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        <u>User Data</u>
      </Typography>
      <Typography variant="body1" align="justify">
        <br />
        User Data is personal data collected directly from Users or the Customer
        organizations they represent or generated by us. We may collect User
        Data from our Users and Customers in a variety of ways, including, by
        email, in connection with the User’s use of the Services and/or when a
        User subscribes to a newsletter.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        We may process the following User Data relating to the Users:
      </Typography>
      <Typography>
        <br />
        • name
        <br />
        • email
        <br />
        • phone number
        <br />
        • password
        <br />
        • marketing opt-ins and opt-outs
        <br />
        • payment details
        <br />
        • the organization the User represents
        <br />
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        <u>Analytics Data</u>
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The logging systems used by the Services automatically log certain
        Analytics Data when you visit the Services. Although we do not normally
        use Analytics Data to identify you as an individual, you can sometimes
        be recognized from it, either alone or when combined or linked with User
        Data. In such situations, Analytics Data can also be considered personal
        data under applicable laws and we will treat such data as personal data.
      </Typography>
      <Typography variant="body1" align="justify">
        We may automatically collect the following Analytics Data when you visit
        or interact with the Services:
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        • <strong>Device Information.</strong> We collect the following
        information relating to the technical device you use when using the
        Services:
        <br />
        o device and device identification number, device IMEI <br />
        o country <br />
        o IP address <br />
        o browser type and version <br />
        o operating system <br />
        o name of your Internet service providers <br />
        o advertising identifier of your device <br />
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        • <strong>Usage Information.</strong> We collect information on your use
        of the Services, such as: <br />
        o time spent in the Services <br />
        o interaction with the Services <br />
        o the time and date of your visits to the Services <br />o the sections
        of the Services you visited
      </Typography>
      <br />
      <Section
        title="Cookies"
        titleStyle="underline"
        paragraphs={[
          "We use various technologies to collect and store Analytics Data and other information when the Users visit the Services, including cookies, pixel tags and web beacons.",
          "Cookies are small text files sent and saved on your device that allows us to identify visitors of the Services and facilitate the use of the Services and to create aggregate information of our visitors. This helps us to improve the Services and better serve our Users. The cookies will not harm your device or files. We use cookies to tailor the Services and the information we provide in accordance with the individual interests of our Users.",
          "The Users may choose to set their web browser to refuse cookies, or to alert when cookies are being sent. For example, the following links provide information on how to adjust the cookie settings on some popular browsers:"
        ]}
      />
      <Typography variant="body1" align="justify">
        <a href="https://support.apple.com/kb/PH19214?viewlocale=en_US&locale=en_US">
          Safari
        </a>
      </Typography>
      <Typography variant="body1" align="justify">
        <a href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">
          Google Chrome
        </a>
      </Typography>
      <Typography variant="body1" align="justify">
        <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">
          Internet Explorer
        </a>
      </Typography>
      <Typography variant="body1" align="justify">
        <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
          Mozilla Firefox
        </a>
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        Please note that some parts of the Services may not function properly if
        use of cookies is refused.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        <u>Web analytics services</u>
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        The Website uses Google Analytics and other web analytics services to
        compile Analytics Data and reports on visitor usage and to help us
        improve the Website. For an overview of Google Analytics, please visit{" "}
        <a
          href="https://marketingplatform.google.com/about/analytics/#?modal_active=none"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics
        </a>
        . It is possible to opt-out of Google Analytics with the following
        browser add-on tool:{" "}
        <a
          href="https://support.google.com/analytics/answer/181881?hl=en&ref_topic=2919631"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Analytics opt-out add-on.
        </a>
      </Typography>
      <br />
      <Typography variant="subtitle2" gutterBottom>
        3. Purposes and legitimate grounds for the processing of your personal
        data
      </Typography>
      <br />
      <Section
        title="Purposes"
        titleStyle="underline"
        paragraphs={[
          "There are several purposes of the processing of your personal data by MYR:"
        ]}
      />
      <Section
        title="To provide the Services"
        titleStyle="italics"
        paragraphs={[
          "MYR processes your personal data to be able to offer the Services to you and to your Customer organization.",
          "If you contact our customer service, we will use the information provided by you to answer your questions or solve your complaint."
        ]}
      />
      <Section
        title="For our legal obligations"
        titleStyle="italics"
        paragraphs={[
          "MYR processes data to enable us to administer and fulfil our obligations under the law. This includes data processed for complying with our bookkeeping obligations and providing information to relevant authorities such as tax authorities."
        ]}
      />
      <Section
        title="For claims handling and legal processes"
        titleStyle="italics"
        paragraphs={[
          "MYR may process personal data in relation to claims handling, debt collection and legal processes. We may also process data for the prevention of fraud, misuse of our services and for data, system and network security."
        ]}
      />
      <Section
        title="For customer communication and marketing"
        titleStyle="italics"
        paragraphs={[
          "MYR processes your personal data to contact you regarding the Services and to inform you of changes relating to it. Your personal data are also used for the purposes of marketing the Services and our other relevant services to you."
        ]}
      />
      <Section
        title="For quality improvement and trend analysis"
        titleStyle="italics"
        paragraphs={[
          "We may also process information about your use of the Services to improve the quality of the Services e.g. by analyzing any trends in the use of the Services. In order to ensure that our services are in line with your needs, personal data can be used for things like customer satisfaction surveys. When possible, we will do this using only aggregated, non-personally identifiable data."
        ]}
      />
      <Section
        title="Legal grounds for processing"
        titleStyle="underline"
        paragraphs={[
          "MYR processes your personal data primarily to pursue our legitimate interest to run, maintain and develop our business and to create and maintain customer and other business relationships. We also process your personal data for our legitimate interest whilst fulfilling our contractual obligations towards our Customers. When choosing to use your data on the basis of our legitimate interests, we weigh our own interests against your right to privacy and e.g. provide you with easy to use opt-out from our marketing communications and use pseudonymized or non-personally identifiable data when possible.",
          "We may also process your personal data in order to comply with legal obligations.",
          "In some parts of the Services, you may be requested to grant your consent for the processing of personal data. In this event, you may withdraw your consent at any time."
        ]}
      />
      <Typography variant="subtitle2" gutterBottom>
        4. Transfer to countries outside Europe
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        MYR stores your personal data primarily within the European Economic
        Area. However, we have service providers in several geographical
        locations. As such, we and our service providers may transfer your
        personal data to, or access it in, jurisdictions outside the European
        Economic Area.
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        We will take steps to ensure that the Users’ personal data receives an
        adequate level of protection in the jurisdictions in which they are
        processed. We provide adequate protection for the transfers of personal
        data to countries outside of the European Economic Area through a series
        of agreements with our service providers based on the{" "}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Standard Contractual Clauses
        </a>{" "}
        or through other appropriate safeguards, such as the{" "}
        <a
          href="https://www.privacyshield.gov/welcome"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Shield Framework.
        </a>{" "}
      </Typography>
      <br />
      <Typography variant="body1" align="justify">
        More information regarding the transfers of personal data may be
        obtained by contacting us on any of the addresses indicated above
      </Typography>
      <br />
      <Section
        title="5. Recipients"
        titleStyle="bold"
        paragraphs={[
          "We only share your personal data within our organization if and as far as reasonably necessary for the purposes of this Privacy Statement.",
          "We do not share your personal data with third parties outside of MYR’s organization unless one of the following circumstances applies:",
          "For the purposes set out in this Privacy Statement and to authorized service providers",
          "To the extent that third parties (such as the Customers) need access to User Data in order for us to perform the Services, we provide such third parties with your data. Please note, that the Customers process such User Data as independent controllers for their own purposes.",
          "Furthermore, we may provide your personal data to our affiliates or to authorized service providers who perform services for us (including data storage, accounting, sales and marketing) to process it for us and to payment service providers to process your payments to us.",
          "When your personal data is processed by third parties as data processors on behalf of MYR, MYR has taken the appropriate contractual and organizational measures to ensure that your data are processed exclusively for the purposes specified in this Privacy Statement and in accordance with all applicable laws and regulations and subject to our instructions and appropriate obligations of confidentiality and security measures.",
          "Please bear in mind that if you provide personal data directly to a third party, such as through a link in the Services, the processing is typically based on their policies and standards."
        ]}
      />
      <Section
        title="For legal reasons and legal processes"
        titleStyle="italics"
        paragraphs={[
          "We may share your personal data with third parties outside our organization if we have a good-faith belief that access to and use of the personal data is reasonably necessary to: (i) meet any applicable law, regulation, and/or court order; (ii) detect, prevent, or otherwise address fraud, crime, security or technical issues; and/or (iii) protect the interests, properties or safety of MYR, the Users or the public as far as in accordance with the law. When possible, we will inform you about such processing."
        ]}
      />
      <Section
        title="For other legitimate reasons"
        titleStyle="italics"
        paragraphs={[
          "If MYR is involved in a merger, acquisition or asset sale, we may transfer your personal data to the third party involved. However, we will continue to ensure the confidentiality of all personal data. We will give notice to all the Users concerned when the personal data are transferred or become subject to a different privacy statement."
        ]}
      />
      <Section
        title="With your explicit consent"
        titleStyle="italics"
        paragraphs={[
          "We may share your personal data with third parties outside MYR when we have your explicit consent to do so. You have the right to withdraw this consent at all times."
        ]}
      />
      <Section
        title="6. Storage period"
        titleStyle="bold"
        paragraphs={[
          "MYR does not store your personal data longer than is legally permitted and necessary for the purposes of this Privacy Statement. The storage period depends on the nature of the information and on the purposes of processing. The maximum period may therefore vary per use.",
          "Most User Data will be deleted after a period of 3 months has lapsed after your Customer organization has terminated their registration to the Services. Thereafter, some of the User Data may be stored by us only as long as such processing is required by law or is reasonably necessary for our legal obligations or legitimate interests such as claims handling, bookkeeping, internal reporting and reconciliation purposes.",
          "We will store Analytics Data for a period of 14 months."
        ]}
      />
      <Section title="7. Your rights" titleStyle="bold" />
      <Section
        title="Right to access"
        titleStyle="italics"
        paragraphs={[
          "You have the right to access and be informed about your personal data processed by us. We give you the possibility to request a copy of your personal data."
        ]}
      />
      <Section
        title="Right to withdraw consent"
        titleStyle="italics"
        paragraphs={[
          "In case the processing is based on a consent granted by the User, the User may withdraw the consent at any time. Withdrawing a consent may lead to fewer possibilities to use the Services. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal."
        ]}
      />
      <Section
        title="Right to rectify"
        titleStyle="italics"
        paragraphs={[
          "You have the right to have incorrect or incomplete personal data we have stored about you corrected or completed by contacting us."
        ]}
      />
      <Section
        title="Right to erasure"
        titleStyle="italics"
        paragraphs={[
          "You may also ask us to delete your personal data from our systems. We will comply with such request unless we have a legitimate ground to not delete the data."
        ]}
      />
      <Section
        title="Right to object"
        titleStyle="italics"
        paragraphs={[
          "You may have the right to object to certain use of your personal data if such data are processed for other purposes than necessary for the provision of the Services or for compliance with a legal obligation. If you object to the further processing of your personal data, this may lead to fewer possibilities to use the Services."
        ]}
      />
      <Section
        title="Right to restriction of processing"
        titleStyle="italics"
        paragraphs={[
          "You may request us to restrict processing of personal data for example when your data erasure, rectification or objection requests are pending and/or when we do not have legitimate grounds to process your data. This may however lead to fewer possibilities to use the Services."
        ]}
      />
      <Section
        title="Right to data portability"
        titleStyle="italics"
        paragraphs={[
          "You have the right to receive the personal data you have provided to us yourself in a structured and commonly used format and to independently transmit those data to a third party."
        ]}
      />
      <Section
        title="How to use your rights"
        titleStyle="italics"
        paragraphs={[
          "The abovementioned rights may be used by sending a letter or an e-mail to us on the addresses set out above, including the following information: the full name, e-mail address and a phone number. We may request the provision of additional information necessary to confirm the identity of the User. We may reject requests that are unreasonably repetitive, excessive or manifestly unfounded."
        ]}
      />
      <Section
        title="8. Direct marketing"
        titleStyle="bold"
        paragraphs={[
          "The User has the right to prohibit us from using the User’s personal data for direct marketing purposes, market research and profiling made for direct marketing purposes by contacting us on the addresses indicated above or by using the functionalities of the Services or the unsubscribe possibility offered in connection with any direct marketing messages."
        ]}
      />
      <Section
        title="9. Lodging a complaint"
        titleStyle="bold"
        paragraphs={[
          "In case the User considers our processing of personal data to be inconsistent with the applicable data protection laws, a complaint may be lodged with the local supervisory authority for data protection. In Finland, the local supervisory authority is the Data Protection Ombudsman (www.tietosuoja.fi)."
        ]}
      />
      <Section
        title="10. Information security"
        titleStyle="bold"
        paragraphs={[
          "We use administrative, organizational, technical, and physical safeguards to protect the personal data we collect and process. Measures include for example, where appropriate, encryption, pseudonymization, firewalls, secure facilities and access right systems. Our security controls are designed to maintain an appropriate level of data confidentiality, integrity, availability, resilience and ability to restore the data. We regularly test the Services, systems, and other assets for security vulnerabilities.",
          "Should despite of the security measures, a security breach occur that is likely to have negative effects to the privacy of the Users, we will inform the relevant Users and other affected parties, as well as relevant authorities when required by applicable data protection laws, about the breach as soon as possible."
        ]}
      />
    </Grid>
  </Grid>
);

const Section = ({ title, paragraphs = [], titleStyle }) => (
  <div>
    <Typography>
      {(titleStyle === "underline" && <u>{title}</u>) ||
        (titleStyle === "italics" && <i>{title}</i>) ||
        (titleStyle === "bold" && <b>{title}</b>) ||
        title}
    </Typography>
    <br />
    {paragraphs.map(p => (
      <div>
        <Typography variant="body1" align="justify">
          {p}
        </Typography>
        <br />
      </div>
    ))}
  </div>
);

export default withStyles(styles)(PrivacyStatementView);
