import React from "react";

import { connect } from "react-redux";

import RadioButtonsGroup from "./RadioButtonsGroup";
import { updatePerformance } from "redux/actions/performanceActions";
import { PERFORMANCE_STATUSES, NEW_STATUS } from "concertify/constants";

const PerformanceUpdateRadioButtons = ({ performance, onChange }) => {
  let currentPerformanceStatus = NEW_STATUS;
  if (PERFORMANCE_STATUSES.includes(performance.Status)) {
    currentPerformanceStatus = performance.Status;
  }

  return (
    <div>
      <RadioButtonsGroup
        selectionOptions={PERFORMANCE_STATUSES}
        startingValue={currentPerformanceStatus}
        onChange={performanceState =>
          onChange(performance.PerformanceId, { Status: performanceState })
        }
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onChange: async (performanceId, payload) => {
    const action = await updatePerformance(performanceId, payload);
    dispatch(action);
  }
});

const mapStateToProps = state => {
  return {
    performance: state.performanceDetailsReducer.currentPerformanceDetails
  };
};

const ConnectedPerformanceUpdateRadioButtons = connect(
  mapStateToProps,
  mapDispatchToProps
)(PerformanceUpdateRadioButtons);

export default ConnectedPerformanceUpdateRadioButtons;
