// @flow strict

import React from "react";
import Auth from "@aws-amplify/auth";
import { ConsoleLogger as Logger } from "@aws-amplify/core";
import { ConfirmSignUp } from "aws-amplify-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import Logo from "components/Logo";
import RootStyle from "../style";

const styles = theme => ({
  ...RootStyle,
  lostYourCodeContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    margin: 0,
    color: theme.palette.grey[300]
  }
});

const logger = new Logger("ForgotPassword");

class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
    this.state = {
      confirmLoading: false,
      resendLoading: false
    };
  }

  confirm = event => {
    if (event) {
      event.preventDefault();
    }
    const username = this.usernameFromAuthData() || this.inputs.username;
    const { code } = this.inputs;
    this.setState({ confirmLoading: true });
    if (!Auth || typeof Auth.confirmSignUp !== "function") {
      this.setState({ confirmLoading: false });
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.confirmSignUp(username, code)
      .then(() => {
        this.setState({ confirmLoading: false });
        this.changeState("signedUp");
      })
      .catch(err => {
        this.setState({ confirmLoading: false });
        this.error(err);
      });
  };

  resend() {
    const username = this.usernameFromAuthData() || this.inputs.username;
    this.setState({ resendLoading: true });
    if (!Auth || typeof Auth.resendSignUp !== "function") {
      this.setState({ resendLoading: false });
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    Auth.resendSignUp(username)
      .then(() => {
        this.setState({ resendLoading: false });
        logger.debug("code resent");
      })
      .catch(err => {
        this.setState({ resendLoading: false });
        this.error(err);
      });
  }

  showComponent(theme) {
    const { classes } = this.props;
    const { confirmLoading, resendLoading } = this.state;
    return (
      <div className={classes.formContainer}>
        <div className={classes.formSection}>
          <div className={classes.headerSection}>
            <div className={classes.logoContainer}>
              <Logo size="large" />
            </div>
            <h3 className={classes.title}>Confirm Sign Up</h3>
            <h5 className={classes.subtitle}>
              Check your email for confirmation code.
            </h5>
          </div>
          <form onSubmit={this.confirm}>
            <div className={classes.bodySection}>
              <TextField type="hidden" name="email" />
              <TextField type="hidden" name="code" />
              <TextField
                className={classes.textField}
                name="username"
                variant="outlined"
                placeholder="Email"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
              <TextField
                className={classes.textField}
                name="code"
                variant="outlined"
                placeholder="Code"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </div>
            <div className={classes.lostYourCodeContent}>
              <span>Lost your code?</span>
              {resendLoading ? (
                <div style={{ marginLeft: 10 }}>
                  <CircularProgress size={21} color="secondary" />
                </div>
              ) : (
                <a href="/#" className={classes.link} onClick={this.resend}>
                  Resend Code
                </a>
              )}
            </div>
            <div className={classes.footerSection}>
              <div className={classes.footerSectionPrimaryContent}>
                {confirmLoading ? (
                  <div className={classes.circularProgressOnButton}>
                    <CircularProgress size={25} color="secondary" />
                  </div>
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Confirm
                  </Button>
                )}
              </div>
              <div className={classes.footerSectionSecondaryContent}>
                <a
                  href="/#"
                  className={classes.link}
                  onClick={() => {
                    super.changeState("signIn");
                  }}
                >
                  Back to Sign in
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CustomConfirmSignUp);
