import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { titleCase } from "utils";

const styles = theme => ({
  label: {
    margin: 0,
    padding: 0
  }
});

const RadioButtonsGroup = ({
  classes,
  selectionOptions,
  startingValue,
  onChange
}) => {
  const handleChange = event => {
    onChange && onChange(event.target.value);
  };

  return (
    <RadioGroup value={startingValue} onChange={handleChange}>
      {Object.values(selectionOptions).map(value => (
        <FormControlLabel
          className={classes.label}
          value={value}
          control={<Radio color="secondary" />}
          label={titleCase(value)}
        />
      ))}
    </RadioGroup>
  );
};

RadioButtonsGroup.propTypes = {
  selectionOptions: PropTypes.object.isRequired,
  startingValue: PropTypes.string,
  onChange: PropTypes.func
};

export default withStyles(styles)(RadioButtonsGroup);
