import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updatePerformance } from "redux/actions/performanceActions";
import { tryOrUndefined } from "utils";
import { PAR } from "concertify/constants";

import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ReactPlaceholder from "react-placeholder";
import { TextRow } from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";
import Link from "@material-ui/core/Link";

import EditingGroup from "forms/EditingGroup";
import PerformanceUpdateRadioButtons from "../status/PerformanceUpdateRadioButtons";
import { Typography } from "@material-ui/core";
import {
  getCMOByCountryCode,
  isLicensingCMOR
} from "concertify/ArtistsAndCMOs";
import { countries } from "utils";
import LicenseDialog from "components/dialogs/LicenseDialog";

import {
  EditableVenueInfo,
  EditableOrganizerInfo
} from "components/forms/editable";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    background: "#fff"
  },
  masterheadline: {
    opacity: 0.71,
    color: "#000",
    fontSize: "20px",
    paddingBottom: theme.spacing(1),
    fontWeight: "bold"
  },
  headline: {
    opacity: 0.3,
    textTransform: "uppercase",
    color: "#000",
    fontSize: "13px"
  },
  subheading: {
    opacity: 0.71,
    color: "#000",
    paddingBottom: theme.spacing(1)
  }
});

class UnstyledPerformanceBackgroundInfo extends React.Component {
  handleVenueUpdate = venueData => {
    let performance = this.props.performance;
    performance.Venue = {
      Name: venueData.Name,
      Address: {
        City: venueData.City,
        CountryCode: venueData.CountryCode.toUpperCase(),
        Line1: venueData.Line1,
        Line2: venueData.Line2,
        Phone: venueData.Phone,
        State: venueData.State,
        ZipCode: venueData.ZipCode
      },
      Website: venueData.Website
    };
    this.props.updatePerformance(this.props.performance.PerformanceId, {
      Venue: performance.Venue
    });
  };

  handleOrganizerUpdate = organizerData => {
    let performance = this.props.performance;
    performance.Organizer = {
      Name: organizerData.Name,
      Website: organizerData.Website,
      Address: {
        Line1: organizerData.Line1,
        Line2: organizerData.Line2,
        ZipCode: organizerData.ZipCode,
        City: organizerData.City,
        State: organizerData.State,
        CountryCode: organizerData.CountryCode,
        Phone: organizerData.Phone
      }
    };
    this.props.updatePerformance(this.props.performance.PerformanceId, {
      Organizer: performance.Organizer
    });
  };

  handleLicenseStateUpdate = licenseState => {
    this.props.updatePerformance(this.props.performance.PerformanceId, {
      Status: licenseState
    });
  };

  handleLicenseUpdate = licenseData => {
    this.props.updatePerformance(this.props.performance.PerformanceId, {
      License: {
        Currency: this.props.performance.License.Currency,
        EstimatedValue: this.props.performance.License.EstimatedValue,
        EstimatedValueDate: this.props.performance.License.EstimatedValueDate
      }
    });
  };

  licensingCMOName = () => {
    const countryCode = tryOrUndefined(this.props.performance, p =>
      p.Venue.Address.CountryCode.toLowerCase()
    );
    try {
      const cmo = getCMOByCountryCode(countryCode);
      return cmo.Name;
    } catch {
      const country = countries[countryCode];
      return `No registered CMO${country ? " for " + country : ""}`;
    }
  };

  createOnChangeHandler = that => (parsed, address, place) => {
    if (
      place &&
      place.structured_formatting &&
      place.structured_formatting.main_text
    ) {
      that.setState(state => {
        state.inputs["Name"] = place.structured_formatting.main_text;
        state.inputs["City"] = parsed.city;
        state.inputs["CountryCode"] = parsed.countryShort;
        state.inputs["Line1"] = parsed.address;
        state.inputs["Line2"] = parsed.stateShort;
        state.inputs["ZipCode"] = parsed.zipCode;
        return state;
      });
    }
  };

  editingGroupPlaceholder = (
    <div>
      <TextRow
        color="lightgrey"
        style={{ width: 120, height: 20, marginBottom: 15, marginTop: 5 }}
      />
      {[...Array(9)].map((textBlock, i) => (
        <div key={i}>
          <TextRow color="lightgrey" style={{ width: 75, height: 12 }} />
          <TextRow
            color="lightgrey"
            style={{ width: 200, height: 10, marginBottom: 24.5 }}
          />
        </div>
      ))}
    </div>
  );

  render() {
    const { classes, isLoading, performance } = this.props;
    console.log("performance:", performance);
    return (
      <div id="PerformanceBackgroundInfo">
        <Paper elevation={2} square className={classes.paper}>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={3} alignItems="center">
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  Venue  
                </Typography>
              </Grid>
              <Grid item>
                {performance && performance.Venue && (
                  <Link
                    target="blank"
                    href={`https://www.google.com/maps/search/${[
                      performance.Venue.Name,
                      performance.Venue.AddresLine1,
                      performance.Venue.Address.City,
                      performance.Venue.Address.CountryCode
                    ].join(",+")}`}
                    id="gmap_link"
                    title="View this venue location on Google Maps"
                    color="secondary"
                    style={{
                      fontSize: "0.7em",
                      textDecoration: "none",
                      verticalAlign: "super"
                    }}
                  >
                    view on Google Maps™
                  </Link>
                )}
              </Grid>
              <ReactPlaceholder
                showLoadingAnimation
                customPlaceholder={this.editingGroupPlaceholder}
                ready={!isLoading && this.props.performance !== undefined}
              >
                <EditableVenueInfo id="venueInfo" />
                <br />
              </ReactPlaceholder>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant="h5" gutterBottom>
                Organizer
              </Typography>
              <ReactPlaceholder
                showLoadingAnimation
                customPlaceholder={this.editingGroupPlaceholder}
                ready={!isLoading && this.props.performance !== undefined}
              >
                <EditableOrganizerInfo id="organizerInfo" />
              </ReactPlaceholder>
            </Grid>

            <Grid item xs={12} md={4}>
              <ReactPlaceholder
                showLoadingAnimation
                customPlaceholder={this.editingGroupPlaceholder}
                ready={!isLoading && this.props.performance !== undefined}
              >
                <>
                  <Typography variant="h5">
                    License & Distribution Financials
                  </Typography>
                  <Typography variant="subtitle1" className={classes.headline}>
                    Licensing CMO
                  </Typography>
                  <Typography
                    variant="subtitle"
                    className={classes.subheading}
                    gutterBottom
                  >
                    {this.licensingCMOName()}
                  </Typography>
                  <EditingGroup
                    key={`${tryOrUndefined(
                      performance,
                      p => p.PerformanceId
                    )}-license`}
                    isLoading={isLoading}
                    classes={classes}
                    inputs={{}}
                    onSaveClick={this.handleLicenseUpdate}
                    showEventValueBox={true}
                    variant={
                      this.props.authenticatedUser &&
                      this.props.authenticatedUser.type
                    }
                    updateButtonAvailable={true}
                    performance={tryOrUndefined(performance, p => p)}
                    readOnly={
                      tryOrUndefined(
                        this.props,
                        p => p.authenticatedUser.type === PAR
                      ) ||
                      tryOrUndefined(
                        this.props,
                        p =>
                          !isLicensingCMOR(p.authenticatedUser, p.performance)
                      )
                    }
                  />
                </>
              </ReactPlaceholder>
            </Grid>

            <Grid item xs={12} md={2}>
              {tryOrUndefined(
                this.props,
                p =>
                  p.authenticatedUser.type === "cmor" &&
                  isLicensingCMOR(p.authenticatedUser, p.performance)
              ) && (
                <ReactPlaceholder
                  showLoadingAnimation
                  customPlaceholder={this.editingGroupPlaceholder}
                  ready={!isLoading && this.props.performance !== undefined}
                >
                  {this.props.performance !== undefined && (
                    <>
                      <Typography variant="h5">Update Status</Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.headline}
                      >
                        Please select status
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.subheading}
                        gutterBottom
                      >
                        <PerformanceUpdateRadioButtons />
                      </Typography>
                    </>
                  )}
                </ReactPlaceholder>
              )}
            </Grid>
          </Grid>
        </Paper>
        <LicenseDialog
          readOnly={
            this.props.authenticatedUser &&
            this.props.performance &&
            this.props.performance.Venue &&
            !isLicensingCMOR(
              this.props.authenticatedUser,
              this.props.performance
            )
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updatePerformance: async (performanceId, payload) => {
      const action = await updatePerformance(performanceId, payload);
      dispatch(action);
    }
  };
};

export const PerformanceBackgroundInfo = withStyles(styles)(
  UnstyledPerformanceBackgroundInfo
);

PerformanceBackgroundInfo.propTypes = {
  performance: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool
};

PerformanceBackgroundInfo.defaultProps = {
  isLoading: false,
  readOnly: false
};

export default connect(null, mapDispatchToProps)(PerformanceBackgroundInfo);
