import { getCMOName } from "concertify/ArtistsAndCMOs";
import { getCountryName } from "utils";

const stringify = f => {
  try {
    return `${f() || ""}`;
  } catch (e) {
    console.error(e);
    return "";
  }
};

const rowsToCSV = (rows, separator) => {
  separator = separator || ";";
  return rows
    .map(row =>
      row.map(cell => `"${cell.replace(/"/g, '""')}"`).join(separator)
    )
    .join("\n");
};

export const performanceTitleRow = [
  "Origin CMO ID",
  "Origin CMO Name",
  "Live performance info origin",
  "Date of live performance info creation",
  "Name of Artist",
  "Date of Live Performance",
  "Start Time of Live Performance",
  "End Time of Live Performance",
  "Status of Live Performance",
  "Venue Name",
  "Venue Street Address 1",
  "Venue Street Address 2",
  "Venue City",
  "Venue Postal Code/ZIP",
  "Venue State or Territory",
  "Venue Country Code",
  "Venue Country",
  "Venue Phone",
  "Venue Website",
  "Venue Email",
  "Organizer Name",
  "Organizer Business ID",
  "Organizer Street Address 1",
  "Organizer Street Address 2",
  "Organizer City",
  "Organizer Postal Code/ZIP",
  "Organizer State or Territory",
  "Organizer Country Code",
  "Organizer Country",
  "Organizer Phone",
  "Organizer Website",
  "Organizer Email",
  "Setlist Status",
  "Source of Work Information",
  "Work Title",
  "ISWC",
  "Work ID at the Origin",
  "Original Duration of Work",
  "Duration of Performance",
  "Last Name of Right Owner",
  "First Name of Right Owner",
  "IPI Number",
  "CAE Role",
  "Performing Rights Society",
  "Concertify Performance ID"
];

const flattenSetlist = (p, normalizer) => {
  const setlist = p.Setlist || { Works: [] };
  if (!setlist.Works || !setlist.Works.length) {
    return [
      [
        "", // Setlist.Status
        "", // Work.OriginName
        "", // Work.Title
        "", // Work.ISWC
        "", // Work.WorkIdAtOrigin
        "", // Work.OriginalDuration
        "", // Work.PerformanceDuration
        "", // RightsOwner.LastName
        "", // RightsOwner.FirstName
        "", // RightsOwner.IPINumber
        "", // RightsOwner.CAERole
        "" // RightsOwner.PerformingRightsSocietyId
      ]
    ];
  }
  return setlist.Works.map(work =>
    work.RightsOwners.map(rightsOwner => [
      normalizer(_ => setlist.Status),
      normalizer(_ => work.OriginName),
      normalizer(_ => work.Title),
      normalizer(_ => work.ISWC),
      normalizer(_ => work.WorkIdAtOrigin),
      normalizer(_ => work.OriginalDuration),
      normalizer(_ => work.PerformanceDuration),
      normalizer(_ => rightsOwner.LastName),
      normalizer(_ => rightsOwner.FirstName),
      normalizer(_ => rightsOwner.IPINumber),
      normalizer(_ => rightsOwner.CAERole),
      normalizer(_ => rightsOwner.PerformingRightsSocietyId)
    ])
  ).flat();
};

const flattenPerformance = (p, normalizer) => {
  let singleRow = [
    normalizer(_ => p.CMOCode),
    normalizer(_ => getCMOName(p.CMOCode)),
    normalizer(_ => p.OriginMetaData.OriginName),
    normalizer(_ => p.OriginMetaData.SubmissionDateTime),
    normalizer(_ => p.PerformingArtist.Name),
    normalizer(_ => p.Date),
    normalizer(_ => p.StartTime),
    normalizer(_ => p.EndTime),
    normalizer(_ => p.Status),
    normalizer(_ => p.Venue.Name),
    normalizer(_ => p.Venue.Address.Line1),
    normalizer(_ => p.Venue.Address.Line2),
    normalizer(_ => p.Venue.Address.City),
    normalizer(_ => p.Venue.Address.ZipCode),
    normalizer(_ => p.Venue.Address.State),
    normalizer(_ => p.Venue.Address.CountryCode),
    normalizer(_ => getCountryName(p.Venue.Address.CountryCode)),
    normalizer(_ => p.Venue.Address.Phone),
    normalizer(_ => p.Venue.Address.Website),
    normalizer(_ => p.Venue.Address.Email),
    normalizer(_ => p.Organizer.Name),
    normalizer(_ => p.Organizer.IdAtOrigin),
    normalizer(_ => p.Organizer.Address.Line1),
    normalizer(_ => p.Organizer.Address.Line2),
    normalizer(_ => p.Organizer.Address.City),
    normalizer(_ => p.Organizer.Address.ZipCode),
    normalizer(_ => p.Organizer.Address.State),
    normalizer(_ => p.Organizer.Address.CountryCode),
    normalizer(_ => getCountryName(p.Organizer.Address.CountryCode)),
    normalizer(_ => p.Organizer.Address.Phone),
    normalizer(_ => p.Organizer.Address.Website),
    normalizer(_ => p.Organizer.Address.Email)
  ];
  const performanceId = normalizer(_ => p.PerformanceId);
  return flattenSetlist(p, normalizer).map(setlistRow => [
    ...singleRow,
    ...setlistRow,
    performanceId
  ]);
};

export const performanceToCSV = (performance, normalizer) => {
  const rows = flattenPerformance(performance, normalizer || stringify);
  return rowsToCSV([performanceTitleRow, ...rows]);
};

const triggerDownload = (event, content, contentType, fileName) => {
  let element = document.createElement("a");
  document.body.appendChild(element);
  element.setAttribute(
    "href",
    window.URL.createObjectURL(
      new Blob([content], {
        type: contentType
      })
    )
  );
  element.setAttribute("download", fileName);
  element.style.display = "";
  element.click();
  document.body.removeChild(element);
  event.stopPropagation();
};

export const downloadPerformanceAsCSV = (
  performance,
  fileName,
  normalizer
) => event => {
  const content = performanceToCSV(performance, normalizer);
  triggerDownload(event, content, "text/csv;charset=utf8;", fileName);
};
