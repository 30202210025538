import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";

import { styles } from "./styles";
import StatusBadge from "../../status/StatusBadge";
import {
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS,
  INVOICED_STATUS,
  NEW_STATUS,
  SCHEDULED_STATUS
} from "../../../concertify/constants";
import { getPropertyOrUndefined, simpleDate, titleCase } from "utils";
import { getCMOName } from "concertify/ArtistsAndCMOs";
import openDialog from "redux/actions/openDialog";
import closeDialog from "redux/actions/closeDialog";

const BadgeComponentFor = status => {
  switch (status) {
    case NEW_STATUS:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
    case SCHEDULED_STATUS:
      return <StatusBadge variant="deepPurple" label={SCHEDULED_STATUS} />;
    case INVOICED_STATUS:
      return <StatusBadge variant="lightBlue" label={INVOICED_STATUS} />;
    case COLLECTED_STATUS:
      return <StatusBadge variant="lightGreen" label={COLLECTED_STATUS} />;
    case DISTRIBUTED_STATUS:
      return <StatusBadge variant="green" label={DISTRIBUTED_STATUS} />;
    default:
      return <StatusBadge variant="orange" label={NEW_STATUS} />;
  }
};

const headCells = [
  {
    id: "Status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    sortable: true
  },
  {
    id: "PerformingArtist.Name",
    numeric: false,
    disablePadding: true,
    label: "Artist",
    sortable: true
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: true,
    label: "Date",
    sortable: true
  },
  {
    id: "Venue.Address.City",
    numeric: false,
    disablePadding: true,
    label: "City",
    sortable: true
  },
  {
    id: "Venue.Address.CountryCode",
    numeric: false,
    disablePadding: true,
    label: "Country",
    sortable: true
  },
  {
    id: "Venue.Name",
    numeric: false,
    disablePadding: true,
    label: "Venue",
    sortable: true
  },
  {
    id: "CMOCode",
    disablePadding: true,
    numeric: true,
    label: "Licensing CMO",
    sortable: false
  },
  { id: "", disablePadding: true, label: "# of songs", sortable: false }
];

const pluralize = (val, word, plural = word + "s") => {
  const _pluralize = (num, word, plural = word + "s") =>
    [1, -1].includes(Number(num)) ? word : plural;
  if (typeof val === "object")
    return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

// Need to split object to access deep nested properties.
const resolve = (path, obj) => {
  return path.split(".").reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj);
};

export const PerformancesTable = ({
  performances,
  onLoading,
  onFinishedLoad
}) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Date");

  const history = useHistory();
  const classes = styles();

  const descendingComparator = (a, b, orderBy) => {
    if (resolve(orderBy, b) < resolve(orderBy, a)) {
      return -1;
    }
    if (resolve(orderBy, b) > resolve(orderBy, a)) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const sort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      onFinishedLoad();
      return stabilizedThis.map(el => el[0]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = property => event => {
    onLoading();
    handleRequestSort(event, property);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="performances-table">
          <TableHead>
            <TableRow className={classes.headerRow}>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  className={classes.headerCell}
                  align="center"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sortable ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                      IconComponent={KeyboardArrowDownIcon}
                      data-role={`sort-performances-by-${headCell.label}`}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    <span>{headCell.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sort(performances, getComparator(order, orderBy)).map(
              (performance, i) => (
                <TableRow
                  hover
                  onClick={() =>
                    history.push(`/performances/${performance.PerformanceId}`)
                  }
                  key={i}
                  className={classes.contentRow}
                  data-role="link-to-performance"
                >
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-status"
                  >
                    {BadgeComponentFor(performance.Status)}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performing-artist-name"
                  >
                    {titleCase(
                      getPropertyOrUndefined(
                        performance.PerformingArtist,
                        "Name"
                      )
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-date"
                  >
                    {simpleDate(performance.Date)}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-city"
                  >
                    {titleCase(
                      getPropertyOrUndefined(performance.Venue.Address, "City")
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-country-code"
                  >
                    {getPropertyOrUndefined(
                      performance.Venue.Address,
                      "CountryCode"
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.rowCell}
                    align="center"
                    data-role="performance-venue"
                  >
                    {performance.Venue.Name}
                  </TableCell>
                  <TableCell className={classes.rowCell} align="center">
                    {getCMOName(performance.CMOCode)}
                  </TableCell>
                  <TableCell className={classes.rowCell} align="center">
                    {(performance.Setlist &&
                      performance.Setlist.Works &&
                      performance.Setlist.Works.length) ||
                      0}{" "}
                    {pluralize(
                      (performance.Setlist &&
                        performance.Setlist.Works &&
                        performance.Setlist.Works.length) ||
                        0,
                      "song"
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  onLoading: () => dispatch(openDialog("BackDropDialog")),
  onFinishedLoad: () => dispatch(closeDialog())
});

export default connect(null, mapDispatchToProps)(PerformancesTable);
