import { combineReducers } from "redux";
import navReducer from "redux/reducers/navReducer";
import performanceDetailsReducer from "redux/reducers/performanceDetailsReducer";
import worksSearchReducer from "redux/reducers/worksSearchReducer";
import loginReducer from "redux/reducers/loginReducer";
import snackbarReducer from "redux/reducers/snackbarReducer";
import dialogReducer from "redux/reducers/dialogReducer";
import performanceCommentReducer from "redux/reducers/performanceCommentReducer";

const rootReducer = combineReducers({
  navReducer,
  performanceDetailsReducer,
  worksSearchReducer,
  loginReducer,
  snackbarReducer,
  dialogReducer,
  performanceCommentReducer
});

export default rootReducer;
