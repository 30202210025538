import { Logger } from "@aws-amplify/core";

import concertify from "concertify/core";
import { LOAD_LATEST_PERFORMANCES_BY_ARTIST_REQUEST } from "redux/actions/types";
import { LOAD_LATEST_PERFORMANCES_BY_ARTIST_SUCCESS } from "redux/actions/types";
import { LOAD_LATEST_PERFORMANCES_BY_ARTIST_ERROR } from "redux/actions/types";
import { OPEN_SNACKBAR } from "redux/actions/types";

const logger = new Logger("loadLatestPerformancesByArtist");

const getLatestPerformancesForArtist = artistName => async dispatch => {
  dispatch({ type: LOAD_LATEST_PERFORMANCES_BY_ARTIST_REQUEST });
  try {
    const artistsPerformances = await concertify.performances.getByArtistName(
      artistName.replace(/\s+/g, "+")
    );
    const formattedSetlists = artistsPerformances.Performances.filter(
      performance =>
        performance.Setlist &&
        performance.Setlist.Works &&
        performance.Setlist.Works.length > 0
    );
    dispatch({
      type: LOAD_LATEST_PERFORMANCES_BY_ARTIST_SUCCESS,
      payload: formattedSetlists
    });
  } catch (e) {
    logger.error(e);
    dispatch({
      type: LOAD_LATEST_PERFORMANCES_BY_ARTIST_ERROR,
      payload: e
    });
    dispatch({
      type: OPEN_SNACKBAR,
      variant: "error",
      message: "Error fetching setlists. Please try again later"
    });
  }
};
export default getLatestPerformancesForArtist;
