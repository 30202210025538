import React from "react";
import PropTypes from "prop-types";

import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    display: "flex",
    height: "80px",
    backgroundColor: "#181843",
    color: "#fff",
    justifyContent: "center"
  },
  navbarContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    padding: "0px 10px"
  },
  left: {
    flex: 1
  }
};

const AppBar = props => {
  const { classes, left, right } = props;
  return (
    <div className={classes.root}>
      <Container disableGutters maxWidth="lg">
        <div className={classes.navbarContainer}>
          <div className={classes.left}>{left}</div>
          <div>{right}</div>
        </div>
      </Container>
    </div>
  );
};

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  left: PropTypes.object,
  right: PropTypes.object
};

export default withStyles(styles)(AppBar);
