import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import urljoin from "url-join";
import { AuthPiece } from "aws-amplify-react";

import PerformancesView from "components/views/PerformancesView";
import PerformanceDetailsView from "components/views/PerformanceDetailsView";
import CreatePerformanceView from "components/views/CreatePerformanceView";
import PrivacyStatementView from "components/views/PrivacyStatementView";
import { PERFORMANCES_PATH, TERMS_OF_SERVICE_PATH } from "concertify/constants";
import PrivacyStatementFooter from "components/PrivacyStatementFooter";
import TermsOfServiceView from "components/views/TermsOfServiceView";
import SessionExpirationDialog from "components/dialogs/SessionExpirationDialog";
import Login from "components/login";
import { verifyTermsOfServiceAcceptance } from "./utils";
import BackDropDialog from "./components/dialogs/BackDropDialog";
import { useIsIdle } from "services/session/isIdleHook";
import UserService from "services/UserService";

// this is not a very robust regex for UUIds since it would
// match any endpoint that's exactly 36 chars in length
const PERFORMANCE_DETAILS_MATCH_PATH = "/performances/:id([\\w\\d\\-]{36})";
const CREATE_PERFORMANCE_ENDPOINT = urljoin(PERFORMANCES_PATH, "new");

class App extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn", "verifyContact", "requireNewPassword"];
    this.state = {};
  }

  showComponent(theme) {
    const session = localStorage.getItem("sessionExpires");

    if (session && new Date(session).getTime() < new Date().getTime()) {
      UserService.signOutUser();
    }

    const tosAcceptance = verifyTermsOfServiceAcceptance(
      this.props.authData.attributes.email
    );
    return tosAcceptance ? (
      <div>
        <Route
          exact={true}
          path="/"
          render={() => <Redirect to={PERFORMANCES_PATH} />}
        />
        <Route
          exact={true}
          path={TERMS_OF_SERVICE_PATH}
          render={() => <Redirect to={PERFORMANCES_PATH} />}
        />
        <Route
          exact={true}
          path={PERFORMANCES_PATH}
          component={PerformancesView}
        />
        <Route
          exact={true}
          path={CREATE_PERFORMANCE_ENDPOINT}
          component={CreatePerformanceView}
        />
        <Route
          exact={true}
          path={PERFORMANCE_DETAILS_MATCH_PATH}
          component={({ match }) => (
            <PerformanceDetailsView performanceId={match.params.id} />
          )}
        />
      </div>
    ) : (
      <div>
        <Route
          exact={true}
          path={TERMS_OF_SERVICE_PATH}
          component={TermsOfServiceView}
        />
        <Redirect to={TERMS_OF_SERVICE_PATH} />
      </div>
    );
  }
}

const AppWithAuth = () => {
  useIsIdle({ timeToIdle: 30000 });
  return (
    <Switch>
      <Route exact path="/privacy-statement" component={PrivacyStatementView} />
      <Login>
        <App />
        <PrivacyStatementFooter />
        <SessionExpirationDialog />
        <BackDropDialog />
      </Login>
    </Switch>
  );
};

export default AppWithAuth;
