import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

import Comment from "components/comments/Comment";
import UserService from "services/UserService";
import { OPEN_SNACKBAR } from "redux/actions/types";
import {
  selectCommentsForPerformance,
  createCommentForPerformance
} from "redux/actions/performanceCommentActions";

const styles = theme => ({
  section: {
    padding: theme.spacing(2),
    color: "#000"
  },
  commentsTextArea: {
    marginBottom: theme.spacing(2)
  },
  submitButton: {
    color: "#fff",
    background: "#8100ff",
    "&:hover": {
      background: "#5200a5"
    }
  }
});

class CommentsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      commentBeingWritten: "",
      AuthorFirstName: undefined,
      AuthorLastName: undefined,
      AuthorCMOCode: undefined,
      SubmissionDateTime: undefined
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    try {
      const user = await UserService.currentAuthenticatedUser();
      this.setState((prevState, _) => ({
        AuthorFirstName: user.name,
        AuthorLastName: user.family_name,
        AuthorCMOCode: user.cmoCode,
        AuthorId: user.sub
      }));
      this.props.selectCommentsForPerformance(this.props.performanceId);
    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleError = e => {
    switch (e.type) {
      default:
        this.setGenericError();
    }
  };

  handleCommentTextAreaChange = e => {
    this.setState({ commentBeingWritten: e.target.value });
  };

  handleSubmitComment = async e => {
    const comment = {
      AuthorId: this.state.AuthorId,
      PerformanceId: this.props.performanceId,
      AuthorFirstName: this.state.AuthorFirstName,
      AuthorLastName: this.state.AuthorLastName || "NoLastName",
      AuthorCMOCode: this.state.AuthorCMOCode,
      Body: this.state.commentBeingWritten,
      SubmissionDateTime: new Date().toISOString()
    };
    this.props.createCommentForPerformance(comment);
    this.setState(state => ({
      submitCommentSuccess: true,
      commentBeingWritten: ""
    }));
  };

  setGenericError = () => {
    this.props.showSnackbar(
      "warning",
      "There was a problem trying to identify the logged-in user. Please try again later."
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div id="comment-section" className={classes.section}>
        <Typography gutterBottom variant="h6">
          Comments
        </Typography>
        <ReactPlaceholder
          showLoadingAnimation
          ready={!this.state.loading}
          rows={3}
        >
          {this.props.comments !== undefined &&
            this.props.comments.length === 0 &&
            "This performance doesn't have any comments yet."}
          {this.props.comments !== undefined &&
            this.props.comments.map(comment => (
              <Comment
                authorFirstName={comment.AuthorFirstName}
                authorLastName={comment.AuthorLastName}
                submissionDateTime={comment.SubmissionDateTime}
                cmoCode={comment.AuthorCMOCode}
              >
                {comment.Body}
              </Comment>
            ))}
        </ReactPlaceholder>
        <br />
        <br />
        <Typography gutterBottom variant="h6">
          Leave a comment
        </Typography>
        <TextField
          id="new-comment"
          multiline
          fullWidth
          value={this.state.commentBeingWritten}
          placeholder="Write something here"
          onChange={this.handleCommentTextAreaChange}
          className={classes.commentsTextArea}
        />
        {this.state.commentBeingWritten ? (
          <Button
            id="SubmitNewComment"
            variant="contained"
            onClick={this.handleSubmitComment}
            className={classes.submitButton}
          >
            Submit
          </Button>
        ) : (
          <Button disabled> Submit </Button>
        )}
      </div>
    );
  }
}

CommentsSection.propTypes = {
  classes: PropTypes.string.isRequired,
  performanceId: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    comments: state.performanceCommentReducer.comments
  };
};

const mapDispatchToProps = dispatch => ({
  showSnackbar: (variant, message) =>
    dispatch({ type: OPEN_SNACKBAR, variant: variant, message: message }),
  selectCommentsForPerformance: performanceId =>
    dispatch(selectCommentsForPerformance(performanceId)),
  createCommentForPerformance: newComment =>
    dispatch(createCommentForPerformance(newComment))
});

const ConnectedCommentsSection = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsSection);

export default withStyles(styles)(ConnectedCommentsSection);
