import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";

const validate = (value, predicates) => predicates.map(p => p(value));

const styles = {
  paperRoot: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid rgba(0, 0, 100, .2)"
  },
  paperRootError: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid rgba(255, 0, 0, .4)"
  },
  input: {
    marginLeft: 8,
    flex: 1,
    padding: "5px 0px"
  },
  fat: {
    padding: "18px 5px"
  },
  icon: {
    marginRight: 12
  }
};

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      errors: [],
      dirty: false,
      valid: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const isValid = validate(e.target.value, this.props.predicates);
    this.setState({ value: e.target.value, valid: isValid });
  }

  inputClass() {
    if (this.props.fat) {
      return [this.props.classes.input, this.props.classes.fat].join(" ");
    }
    return this.props.classes.input;
  }

  // FIXME: I give up trying to fix this
  paperClass() {
    if (this.props.error) {
      return { root: this.props.classes.paperRootError };
    }
    return { root: this.props.classes.paperRoot };
  }

  render() {
    return (
      <div>
        {this.props.label && (
          <label htmlFor={this.props.htmlFor}>
            {this.props.label}
            {this.props.required && "*"}
          </label>
        )}

        <Paper classes={this.paperClass()} {...this.props}>
          <InputBase
            fullWidth
            className={this.inputClass()}
            {...this.props}
            endAdornment={
              <InputAdornment
                className={this.props.classes.icon}
                position="end"
              >
                {this.props.icon}
              </InputAdornment>
            }
          />
        </Paper>

        {!this.state.valid && (
          <ul>
            {this.state.errors.map(e => (
              <li>{e}</li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

Input.propTypes = {
  required: PropTypes.bool,
  predicates: PropTypes.arrayOf(PropTypes.func),
  label: PropTypes.string,
  icon: PropTypes.func,
  fat: PropTypes.bool
};

Input.defaultProps = {
  type: "text",
  required: false,
  predicates: []
};

export default withStyles(styles)(Input);
