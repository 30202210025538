import React, { useState } from "react";
import PropTypes from "prop-types";

import concertify from "concertify/core";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import red from "@material-ui/core/colors/red";
import ReactPlaceholder from "react-placeholder";

import "react-placeholder/lib/reactPlaceholder.css";
import moment from "moment";

import { titleCase } from "utils";

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1),
    fontFamily: "sans-serif",
    fontSize: 14,
    lineHeight: "1.5em",
    paddingBottom: theme.spacing(1),
    boxShadow: "none"
  },
  collapseIconButton: {
    color: "gray",
    marginRight: theme.spacing(2)
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    padding: theme.spacing(2),
    whiteSpace: "pre-line"
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  danger: {
    color: red[500]
  },
  edited: {
    opacity: 0.5
  }
});

const Comment = props => {
  const {
    children,
    collapsed,
    authorFirstName,
    authorLastName,
    submissionDateTime,
    cmoCode,
    edited,
    loading,
    classes
  } = props;
  const [isCollapsed, setCollapsed] = useState(collapsed);
  return (
    <Paper id="newComment" time={submissionDateTime} className={classes.root}>
      <div className={classes.header}>
        <IconButton
          onClick={e => setCollapsed(!isCollapsed)}
          className={classes.collapseIconButton}
        >
          <ExpandMoreIcon />
        </IconButton>
        <ReactPlaceholder showLoadingAnimation ready={!loading}>
          {titleCase(authorFirstName)} {titleCase(authorLastName)}
          {cmoCode &&
            " from " + concertify.mapCMOCodeToName(cmoCode) + " said on "}
          {moment(submissionDateTime).format("MMMM Do YYYY h:mm:ss a")}
          {edited && <span className={classes.edited}>{" (edited)"}</span>}
        </ReactPlaceholder>
      </div>
      {!isCollapsed && (
        <div>
          <div className={classes.body}>
            <ReactPlaceholder showLoadingAnimation rows={3} ready={!loading}>
              {children}
            </ReactPlaceholder>
          </div>

          {/* Not dead code; just sleeping
          <div className={classes.actions}>
            <ReactPlaceholder
              showLoadingAnimation
              ready={!loading}
              style={{ width: 80 }}
            >
              <Button className={classes.danger}>Delete</Button>
            </ReactPlaceholder>
          </div>o
          */}
        </div>
      )}
    </Paper>
  );
};

Comment.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string,
  collapsed: PropTypes.bool,
  authorFirstName: PropTypes.string.isRequired,
  authorLastName: PropTypes.string.isRequired,
  submissionDateTime: PropTypes.string.isRequired,
  cmoCode: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  edited: PropTypes.bool,
  loading: PropTypes.bool
};

Comment.defaultProps = {
  collapsed: false,
  authorFirstName: "Unknown",
  authorLastName: "User",
  edited: false,
  loading: false
};

export default withStyles(styles)(Comment);
