import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
  searchBox: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  }
});

const SearchBox = ({ classes, ...rest }) => (
  <Paper className={classes.searchBox}>
    <InputBase {...rest} />
    <SearchIcon style={{ color: "#000" }} />
  </Paper>
);

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SearchBox);
