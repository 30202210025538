/**
 * This module exports a presentational PerformancesSummary component as a secondary export and
 * the same component connected to the Redux store as a default export. It is done this way because
 * getting the props of the current performance from the Redux store is the most common use case, while
 * still providing flexibility through the secondary export shall the props ever need to come from another source.
 */
import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

import StatusBadge from "components/status/StatusBadge";
import { simpleDate, titleCase } from "utils";
import {
  NEW_STATUS,
  SCHEDULED_STATUS,
  INVOICED_STATUS,
  COLLECTED_STATUS,
  DISTRIBUTED_STATUS
} from "concertify/constants";

const styles = theme => ({
  paper: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    background: "#323355"
  },
  headline: {
    fontSize: 14,
    opacity: 0.5,
    textTransform: "uppercase",
    color: "#fff"
  },
  subheading: {
    color: "#fff",
    paddingBottom: theme.spacing(1)
  }
});

const MappedStatusBadge = ({ status, loading }) => {
  switch (status) {
    case NEW_STATUS:
      return <StatusBadge variant="orange" label={status} loading={loading} />;
    case SCHEDULED_STATUS:
      return (
        <StatusBadge variant="deepPurple" label={status} loading={loading} />
      );
    case INVOICED_STATUS:
      return (
        <StatusBadge variant="lightBlue" label={status} loading={loading} />
      );
    case COLLECTED_STATUS:
      return (
        <StatusBadge variant="lightGreen" label={status} loading={loading} />
      );
    case DISTRIBUTED_STATUS:
      return <StatusBadge variant="green" label={status} loading={loading} />;
    default:
      return <StatusBadge variant="gray" label="unknown" loading={loading} />;
  }
};

const UnstyledPerformanceSummary = props => {
  const {
    classes,
    performance,
    isLoading,
    representatives,
    homeCMO,
    source
  } = props;

  return (
    <div>
      <Paper
        elevation={2}
        square
        className={classes.paper}
        id="PerformanceSummary"
      >
        <Grid container>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" className={classes.headline}>
              performing artist
            </Typography>
            <Typography variant="subtitle1" className={classes.subheading}>
              {!isLoading && performance.PerformingArtist === null
                ? "Not available"
                : loading(
                    performance.PerformingArtist && (
                      <div id="performingArtistName">
                        {titleCase(performance.PerformingArtist.Name)}
                      </div>
                    ),
                    250
                  )}
            </Typography>
            <Typography variant="h5" className={classes.headline}>
              artist representatives
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subheading}
              gutterBottom
            >
              {!isLoading && representatives === null
                ? "None"
                : loading(representatives, 250)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" className={classes.headline}>
              Date
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subheading}
              gutterBottom
            >
              {!isLoading && performance.Date === null
                ? "Not available"
                : loading(
                    performance.Date && (
                      <div id="performanceDate">
                        {simpleDate(performance.Date)}
                      </div>
                    ),
                    150
                  )}
            </Typography>
            <Typography variant="h5" className={classes.headline}>
              artist home cmo
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subheading}
              gutterBottom
            >
              <div id="homeCMO">
                {!isLoading
                  ? homeCMO || "Unknown"
                  : loading(performance.CMO, 250)}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" className={classes.headline}>
              status
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subheading}
              gutterBottom
            >
              {loading(
                <MappedStatusBadge
                  status={performance.Status}
                  loading={isLoading}
                />,
                250
              )}
            </Typography>
            <Typography variant="h5" className={classes.headline}>
              source of event information
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.subheading}
              gutterBottom
            >
              {(!isLoading && performance.SubmitterId && "Concertify User") ||
                (source === null ? "Not available" : loading(source, 250))}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

/**
 * PerformanceSummary is a component representing a small, summarized version of a full Performance.
 * By default, all `undefined` props are assumed to be in a "loading" state (being fetched from a remote server
 * or somewhere else) and as such are displayed as a glowing "Loading" animation. The only props with an additional
 * valid value are `representatives` and `cmo`, both of which also accept `null` as a valid value and will display "None"
 * and "Unknown" respectively.
 */
export const PerformanceSummary = withStyles(styles)(
  UnstyledPerformanceSummary
);

const loading = (value, width) => {
  if (value === undefined) {
    return (
      <ReactPlaceholder
        showLoadingAnimation
        color="#474766"
        type="text"
        style={{ width: width, paddingBottom: "3px", paddingTop: "5px" }}
      />
    );
  }
  return value;
};

PerformanceSummary.propTypes = {
  representatives: PropTypes.array,
  source: PropTypes.string,
  status: PropTypes.string
};

PerformanceSummary.defaultProps = {
  representatives: null,
  source: "Concertify",
  status: NEW_STATUS
};

export default PerformanceSummary;
