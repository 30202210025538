/**
 * This module defines all the necessary components needed to render a fully-working Performance Details View.
 * Its default export is a PerformanceDetailsView component which already includes all the logic necessary to
 * fetch and display the information of a single performance given a valid `performanceId`.
 *
 * It additionally exposes a pure PresentationalPerformanceDetailsView component as a secondary export. This component
 * takes a single `performance` prop representing a Performance object and displays the Performance object's information without
 * any sort of remote calls or side-effects.
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { OPEN_DIALOG, DELETE_PERFORMANCE } from "redux/actions/types";

import UserService from "services/UserService";

import { PAR } from "concertify/constants";
import SetlistSection from "./_SetlistSection";
import PerformanceDetailsSubMenu from "components/PerformanceDetailsSubMenu";
import PerformanceStatusStepper from "components/status/PerformanceStatusStepper";
import { PerformanceSummary } from "components/performances";
import PerformanceBackgroundInfo from "components/performances/PerformanceBackgroundInfo";
import loadPerformance from "redux/actions/loadPerformance";
import saveSetlist from "redux/actions/saveSetlist";
import { currentAuthenticatedUser } from "redux/actions/loginActions";
import DeletePerformanceConfirmationDialog from "components/dialogs/DeletePerformanceConfirmationDialog";
import MainBody from "components/structural/MainBody";
import CommentsSection from "./CommentsSection";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import { tryOrUndefined } from "utils";
import { downloadPerformanceAsCSV } from "concertify/exporting";
import { getCMOName } from "concertify/ArtistsAndCMOs";
import AddWorkDialog from "components/dialogs/AddWorkDialog";

const styles = theme => ({
  gray: {
    background: "#cfd5d8",
    marginRight: theme.spacing(2),
    color: "#62627d"
  },
  blue: {
    background: "#028dc7",
    color: "#fff"
  },
  orange: {
    background: "#ff7401",
    color: "#fff",
    "&:hover": {
      background: "#ea6500"
    }
  },
  gutter: {
    marginTop: "10px",
    marginRight: "10px"
  },
  sectionHeading: {
    textTransform: "uppercase",
    fontSize: 14,
    color: "#fff",
    opacity: 0.5,
    letterSpacing: 1
  },
  darkSection: {
    background: "#363762",
    padding: theme.spacing(2)
  },
  setlistSection: {
    padding: theme.spacing(2),
    background: "#F5F7F8"
  },
  message: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2)
  },
  danger: {
    color: red[500],
    marginLeft: theme.spacing(1)
  },
  success: {
    color: green[500],
    marginLeft: theme.spacing(1)
  },
  warning: {
    padding: theme.spacing(1),
    borderRadius: 5
  },
  addWorkButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      cursor: "pointer"
    }
  },
  margins: {
    marginTop: 3,
    marginBottom: theme.spacing(2)
  },
  unconfirmedButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.light300,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    }
  },
  clearSetlistButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
    "&:hover": {
      backgroundColor: theme.palette.grey[700]
    }
  }
});

/**
 * PerformanceDetailsFetcher is a HOC component responsible for fetching information about a single Live Performance.
 * It takes a single `performanceId` prop referring to the ID of the performance to fetch.
 * In its current implementation it doesn't take any custom wrapped Component to inject props into, but rather
 * returns an instance of a PresentationalPerformancesView with its `performance` prop set to the fetched Performance.
 */
class PerformanceDetailsView extends React.Component {
  constructor(props) {
    super(props);
    // if invalid state ... fail loudly!
    if (!props.performanceId) {
      throw new Error(
        "Hi, I am the PerformanceDetailsFetcher. If you're seeing this, it means that I was initialized without a performanceId prop, which is very, very bad and makes me cry."
      );
    }
    this.state = {
      authenticatedUserType: undefined
    };
  }

  async componentDidMount() {
    try {
      const user = await UserService.currentAuthenticatedUser();
      this.setState({
        authenticatedUserType: user.type,
        user: user
      });
    } catch (e) {
      console.error(e);
    }
    this.props.loadPerformance(this.props.performanceId);
  }

  saveSetlist = async works =>
    await this.props.saveSetlist(this.props.performanceId, works);

  render = () => {
    return (
      <PresentationalPerformanceDetailsView
        userType={this.state.authenticatedUserType}
        performance={this.props.performance}
        performanceId={this.props.performanceId}
        saveSetlist={this.saveSetlist}
        hideComments={this.state.authenticatedUserType === PAR}
        authenticatedUser={this.state.user}
        toggles={{}}
      />
    );
  };
}

export const UnstyledPresentationalPerformanceDetailsView = props => {
  const {
    performance,
    userType,
    classes,
    performanceId,
    hideComments,
    authenticatedUser
  } = props;
  if (performance === undefined) {
    throw new Error(
      'PresentationalPerformanceDetailsView: an undefined "performance" prop represents an invalid state'
    );
  }

  const getPerformanceSource = performance => {
    const submitterId = tryOrUndefined(
      performance,
      p => p.OriginMetaData.SubmitterId
    );
    if (submitterId === "1") {
      return "Online ticket vendor";
    }
    const submitterCMOCode = tryOrUndefined(
      performance,
      p => p.OriginMetaData.SubmitterCMOCode
    );
    if (!submitterCMOCode) {
      return "Concertify";
    }
    try {
      return getCMOName(submitterCMOCode);
    } catch (e) {
      console.error(e);
    }
    return submitterCMOCode;
  };

  return (
    <MainBody subNavComponent={<PerformanceDetailsSubMenu />}>
      <PerformanceStatusStepper status={performance.status} />

      <PerformanceSummary
        performance={performance}
        isLoading={Object.keys(performance).length === 0}
        source={getPerformanceSource(performance)}
        homeCMO={getCMOName(performance.CMOCode)}
      />

      <PerformanceBackgroundInfo
        readOnly={userType === PAR}
        performance={performance}
        isLoading={Object.keys(performance).length === 0}
        authenticatedUser={authenticatedUser}
      />

      <SetlistSection classes={classes} />
      <AddWorkDialog open={false} />

      {!hideComments && <CommentsSection performanceId={performanceId} />}

      <ActionsSection
        classes={classes}
        performance={performance}
        authenticatedUser={authenticatedUser}
      />
      <DeletePerformanceConfirmationDialog deleter={authenticatedUser} />
    </MainBody>
  );
};

const ActionsSection = props => {
  const { classes, performance, authenticatedUser } = props;
  const fileName = `ConcertifyLivePerformance_${performance.Date ||
    "Unknown Date"}_${(performance.PerformingArtist &&
    performance.PerformingArtist.Name) ||
    "Unknown Artist"}_${(performance.Venue && performance.Venue.Name) ||
    "Unknown Venue"}_${performance.PerformanceId}`.replace(/ /g, "-");
  return (
    <div className={classes.darkSection}>
      <Typography gutterBottom variant="h6" className={classes.sectionHeading}>
        Performance Actions
      </Typography>
      {authenticatedUser && authenticatedUser.type !== PAR && (
        <>
          <Button
            data-role="download-performance-as-csv-button"
            variant="contained"
            className={classes.gray}
            onClick={downloadPerformanceAsCSV(performance, `${fileName}.csv`)}
          >
            Download as CSV
          </Button>
        </>
      )}
      <ReduxDeletePerformanceButton classes={classes} />
    </div>
  );
};

const DeletePerformanceButton = props => {
  const { classes, onClick } = props;
  return (
    <Button onClick={onClick} variant="contained" className={classes.orange}>
      Delete
    </Button>
  );
};

const mapDispatchToProps1 = dispatch => ({
  onClick: () => dispatch({ type: OPEN_DIALOG, variant: DELETE_PERFORMANCE })
});

const ReduxDeletePerformanceButton = connect(
  null,
  mapDispatchToProps1
)(DeletePerformanceButton);

/**
 * PresentationalPerformanceDetailsView is a functional component that takes a single `performance` prop
 * and renders a detailed view of the performance's information.
 */
export const PresentationalPerformanceDetailsView = withStyles(styles)(
  UnstyledPresentationalPerformanceDetailsView
);

PerformanceDetailsView.propTypes = {
  loadPerformance: PropTypes.func.isRequired,
  saveSetlist: PropTypes.func.isRequired,
  performanceId: PropTypes.string.isRequired,
  performance: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  performance: state.performanceDetailsReducer.currentPerformanceDetails
});

/**
 * PerformanceDetailsView takes a single `performanceId` indicating the performance that should be loaded. It
 * includes logic for fetching the performance's information, updating the Redux store with the fetched performance,
 * and displaying it by a regular redux prop to state mapping.
 */
export default connect(mapStateToProps, {
  currentAuthenticatedUser,
  saveSetlist,
  loadPerformance
})(PerformanceDetailsView);
