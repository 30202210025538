import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Input from "components/forms/Input";
import { subheadings, countries, addProtocolToURL } from "../utils";

import CountryPicker from "../components/CountryPicker";

const EditingItem = props => {
  const { classes, label, value, isEditing, onBlur, presetComponent } = props;

  const renderCorrectValue = () => {
    if (value && label === "CountryCode") {
      return countries[value.toLowerCase()];
    }
    if (value && label === "Website") {
      return <a href={addProtocolToURL(value)}>{value}</a>;
    }
    return value;
  };

  const renderCorrectEditingOption = () => {
    if (presetComponent) return presetComponent;
    if (label === "CountryCode")
      return <CountryPicker currentCountry={value} onCountryChange={onBlur} />;
    return (
      <Input style={{ width: 250 }} defaultValue={value} onBlur={onBlur} />
    );
  };

  const item = (
    <div>
      <Typography variant="subtitle1" className={classes.headline}>
        {subheadings[label]}
      </Typography>
      <Typography
        variant="subtitle1"
        className={isEditing ? classes.editing : classes.subheading}
        gutterBottom
      >
        {isEditing
          ? renderCorrectEditingOption()
          : renderCorrectValue() || "Not available"}
      </Typography>
    </div>
  );

  const isStateUndefined = label === "State" && value === undefined;

  if (isStateUndefined) {
    return null;
  }
  return item;
};

const styles = theme => ({
  headline: {
    opacity: 0.3,
    textTransform: "uppercase",
    color: "#000",
    fontSize: "13px"
  },
  subheading: {
    opacity: 0.71,
    color: "#000",
    paddingBottom: theme.spacing(1)
  },
  editing: {
    color: "#000"
  }
});

EditingItem.propTypes = {
  onBlur: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  isEditing: PropTypes.bool,
  presetComponent: PropTypes.object
};

EditingItem.defaultProps = {
  label: "",
  isEditing: false
};

export default withStyles(styles)(EditingItem);
