import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CreatePerformanceViewHeader from "components/CreatePerformanceViewHeader";
import MainBody from "components/structural/MainBody";
import Input from "components/forms/Input";
import GoogleMapsSuggest from "components/forms/GoogleMapsSuggest";
import Button from "@material-ui/core/Button";
import UserService from "services/UserService";
import { CMOR } from "concertify/constants";
import { currentAuthenticatedUser } from "redux/actions/loginActions";
import { createPerformance } from "redux/actions/performanceActions";

import { withStyles } from "@material-ui/core/styles";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import Select from "@material-ui/core/Select";
import { withRouter } from "react-router-dom";
import { countries } from "../../utils";

const styles = {
  root: {
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    background: "#D9DFE3",
    lineHeight: "1.2em"
  },
  subwrapper: {
    padding: "10px 20px",
    color: "rgba(0, 0, 0, .67)"
  },
  title: {
    marginTop: "10px",
    lineHeight: "1.4em"
  },
  form: {
    width: 350
  },
  fieldWrapper: {
    paddingBottom: "10px"
  },
  buttonWrapperLeft: {
    marginRight: "15px"
  },
  buttonWrapperRight: {
    marginLeft: "15px",
    display: "inline"
  }
};

const FooterLeft = ({ classes, goBack, doSave }) => (
  <div className={classes.buttonWrapperRight}>
    <Button
      id="CancelAddNewPerformanceButton"
      variant="contained"
      onClick={goBack}
    >
      Cancel
    </Button>
  </div>
);

const FooterRight = ({ classes, disabled, doSave }) => (
  <div>
    <div className={classes.buttonWrapperRight}>
      <Button
        id="SubmitAddNewPerformanceFormButton"
        disabled={disabled}
        variant="contained"
        color="secondary"
        onClick={doSave}
      >
        Save
      </Button>
    </div>
  </div>
);

export const NewPerformance = ({
  classes,
  user,
  artist,
  date,
  venue,
  city,
  country,
  addressLine1,
  addressLine2,
  zipCode,
  organizer,
  handleChange,
  handleChangeVenue
}) => (
  <div className={classes.subwrapper}>
    <h1 className={classes.title}>New performance</h1>
    <form className={classes.form}>
      {user && user.type === CMOR && (
        <div className={classes.fieldWrapper}>
          <Select
            native
            value={artist}
            error={!artist}
            required={true}
            onChange={handleChange}
            input={
              <Input
                type="text"
                id="performanceArtist"
                name="artist"
                label="Performing Artist"
                htmlFor="performanceArtist"
              />
            }
          >
            <option value="" />
            {user.performingArtists.map(entry => {
              return <option value={entry.Name}>{entry.Name}</option>;
            })}
          </Select>
        </div>
      )}
      <div className={classes.fieldWrapper}>
        <Input
          type="date"
          id="performanceDate"
          name="date"
          value={date}
          error={!date}
          required={true}
          label="Date"
          onChange={handleChange}
          htmlFor="performanceDate"
          placeholder="Select date"
          icon={<CalendarIcon />}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <GoogleMapsSuggest
          apiKey={process.env.REACT_APP_GCP_MAPS_API_KEY}
          id="performanceVenue"
          name="venue"
          value={venue}
          error={!venue}
          required={true}
          label="Venue"
          htmlFor="performanceVenue"
          onChange={handleChangeVenue}
          onBlur={handleChange}
          placeholder="Find venue"
          autoComplete="off"
          icon={<SearchIcon />}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Input
          type="text"
          id="performanceAddressLine1"
          name="addressLine1"
          value={addressLine1}
          error={!addressLine1}
          required={true}
          label="Address Line 1"
          onChange={handleChange}
          htmlFor="performanceAddressLine1"
          placeholder="Enter address line 1"
          autoComplete="off"
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Input
          type="text"
          id="performanceAddressLine2"
          name="addressLine2"
          value={addressLine2}
          label="Address Line 2"
          onChange={handleChange}
          htmlFor="performanceAddressLine2"
          placeholder="Enter address line 2"
          autoComplete="off"
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Input
          type="text"
          id="performanceZipCode"
          name="zipCode"
          value={zipCode}
          error={!zipCode}
          required={true}
          label="Zip Code"
          onChange={handleChange}
          htmlFor="performanceZipCode"
          placeholder="Enter zip code"
          autoComplete="off"
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Input
          type="text"
          id="performanceCity"
          name="city"
          value={city}
          error={!city}
          required={true}
          label="City"
          onChange={handleChange}
          htmlFor="performanceCity"
          placeholder="Enter city"
          autoComplete="off"
        />
      </div>
      <div className={classes.fieldWrapper}>
        <Select
          native
          value={country && country.toLowerCase()}
          error={!country}
          required={true}
          onChange={handleChange}
          input={
            <Input
              type="text"
              value={country}
              defaultValue={country}
              id="performanceCountry"
              name="country"
              label="Country"
              htmlFor="performanceCountry"
            />
          }
        >
          <option value="" />
          {Object.entries(countries).map(entry => {
            return <option value={entry[0]}>{entry[1]}</option>;
          })}
        </Select>
      </div>
    </form>
  </div>
);

class UnconnectedCreatePerformanceView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artist: null,
      date: this.props.date,
      venue: this.props.venue,
      organizer: this.props.organizer,
      city: null,
      country: null,
      addressLine1: null,
      addressLine2: null,
      zipCode: null
    };
  }

  // TODO: move this logic into a separate wrapping component
  async componentDidMount() {
    const user = await UserService.currentAuthenticatedUser();
    this.setState({ user: user });
  }

  doSave = async () => {
    const performance = {
      artist: this.state.artist,
      date: this.state.date,
      city: this.state.city,
      country: this.state.country.toUpperCase(),
      venue: this.state.venue,
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      zipCode: this.state.zipCode,
      cmoCode: this.state.user.cmoCode
    };
    try {
      const newPerformance = await this.props.createPerformance(performance);
      this.props.history.push(`/performances/${newPerformance.PerformanceId}`);
    } catch (e) {
      throw new Error(e);
    }
  };

  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((oldState, oldProps) => {
      let newState = {};
      newState[name] = value;
      return newState;
    });
  };

  handleChangeVenue = (parsed, address, place) => {
    if (
      place &&
      place.structured_formatting &&
      place.structured_formatting.main_text
    ) {
      this.setState({
        venue: place.structured_formatting.main_text,
        city: parsed.city,
        country: parsed.countryShort,
        addressLine1: parsed.address,
        addressLine2: parsed.stateShort,
        zipCode: parsed.zipCode
      });
    }
  };

  isDisabled = () =>
    !this.state.artist ||
    !this.state.date ||
    !this.state.venue ||
    !this.state.addressLine1 ||
    !this.state.zipCode ||
    !this.state.city ||
    !this.state.country;

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid
          container
          style={{ background: "#EDEFF0", minHeight: "100vh" }}
          direction="column"
          justify="flex-start"
          alignItems="stretch"
        >
          <MainBody hideSubNav={true}>
            <NewPerformance
              classes={this.props.classes}
              user={this.state.user}
              artist={this.state.artist}
              date={this.state.date}
              venue={this.state.venue}
              city={this.state.city}
              country={this.state.country}
              addressLine1={this.state.addressLine1}
              addressLine2={this.state.addressLine2}
              zipCode={this.state.zipCode}
              organizer={this.state.organizer}
              handleChange={this.handleChange}
              handleChangeVenue={this.handleChangeVenue}
            />
          </MainBody>

          <CreatePerformanceViewHeader
            left={
              <FooterLeft
                classes={this.props.classes}
                goBack={this.props.history.goBack}
              />
            }
            right={
              <FooterRight
                disabled={this.isDisabled()}
                classes={this.props.classes}
                doSave={this.doSave}
              />
            }
          />
        </Grid>
      </div>
    );
  }
}

UnconnectedCreatePerformanceView.propTypes = {
  currentAuthenticatedUser: PropTypes.func.isRequired,
  createPerformance: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  activeStep: PropTypes.number,
  date: PropTypes.object,
  venue: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.loginReducer.user
});

const CreatePerformanceView = connect(mapStateToProps, {
  currentAuthenticatedUser,
  createPerformance
})(UnconnectedCreatePerformanceView);

export default withRouter(withStyles(styles)(CreatePerformanceView));
