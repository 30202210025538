// @flow strict

import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogTitle
} from "@material-ui/core";
import closeDialog from "redux/actions/closeDialog";
import UserService from "services/UserService";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    color: "#5A5A5A"
  },
  mainText: {
    fontSize: 16,
    color: "#141414"
  },
  subText: {
    fontSize: 16,
    color: "#5A5A5A"
  }
}));

type SessionExpirationDialogProps = {
  /** If true, the Dialog is open. */
  open: boolean,
  /** Function which is called when session is expired */
  onSessionExpired: Function
};

export const SessionExpirationDialog = ({
  open,
  onSessionExpired
}: SessionExpirationDialogProps) => {
  const classes = useStyles();

  const signOutUser = () => {
    setTimeout(() => {
      onSessionExpired();
      UserService.signOutUser();
    }, 3000);
  };

  return (
    <Dialog open={open} onEntered={signOutUser}>
      <DialogTitle id="SessionExpirationDialog">
        <Typography className={classes.title}>Session Timeout</Typography>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Typography className={classes.mainText}>
          Session timed out, please sign in again
        </Typography>
        <Typography className={classes.subText}>
          You will be redirected in 3 seconds
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

SessionExpirationDialog.defaultProps = {
  open: false
};

const mapStateToProps = state => {
  return {
    open:
      state.dialogReducer.isOpen === true &&
      state.dialogReducer.variant === "SessionExpiration"
  };
};

const mapDispatchToProps = dispatch => ({
  onSessionExpired: () => dispatch(closeDialog())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionExpirationDialog);
